<template>
    <div>
        <template v-if="country">
            <section class="header-image">
                <div class="header-image-overlay"></div>
                <b-container>
                    <b-row>
                        <b-col lg="7"><h1>{{ $t('message.vsat_internet_satellite.title', { countryName: country.name }) }}</h1></b-col>
                        <b-col lg="4">
                            <div class="header-breadcrumb">
                                <router-link class="link-alt" :to="{ name: $i18n.locale + '_home' }">{{ $t('message.home.title') }}</router-link>
                                • <span class="text-muted" id="country">{{ country.name }}</span>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>
            </section>

            <section class="section">
                <b-container>
                    <b-row>
                        <b-col md="8" xl="9">
                            <b-card bg-variant="light" class="mb-3">
                                <b-card-text>{{ $t('message.vsat_internet_satellite.card_1') }}</b-card-text>
                            </b-card>
                            <b-card bg-variant="light" class="mb-3">
                                <b-card-text>{{ $t('message.vsat_internet_satellite.card_2', { countryName: country.name }) }}</b-card-text>
                            </b-card>
                            <b-card bg-variant="light" class="mb-3">
                                <b-card-text>{{ $t('message.vsat_internet_satellite.card_3', { countryName: country.name }) }}</b-card-text>
                            </b-card>
                            <p>
                                {{ $t('message.vsat_internet_satellite.paragraph_1', { countryName: country.name }) }}
                            </p>
                            <p>
                                {{ $t('message.vsat_internet_satellite.paragraph_2', { countryName: country.name }) }}
                            </p>
                            <p></p>
                            <table class="table table-landing mt-4 mb-4">
                                <thead>
                                <tr>
                                    <th class="text-center d-none d-md-table-cell">{{ $t('message.vsat_internet_satellite.table.country') }}</th>
                                    <th class="text-center"><span class="li-cloud fa-2x"></span><br>{{ $t('message.vsat_internet_satellite.table.availability') }}</th>
                                    <th class="text-center"><span class="li-global fa-2x"></span><br>{{ $t('message.vsat_internet_satellite.table.internet_access') }}</th>
                                    <th class="text-center"><span class="li-linegraph fa-2x"></span><br>{{ $t('message.vsat_internet_satellite.table.link_to_mpls') }}</th>
                                    <th class="text-center"><span class="li-genius fa-2x"></span><br>{{ $t('message.vsat_internet_satellite.table.vsat_license') }}</th>
                                    <th class="text-center"><span class="li-map fa-2x"></span><br>{{ $t('message.vsat_internet_satellite.table.on_field_support') }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td class="text-center d-none d-md-table-cell">{{ country.name }}</td>
                                    <td class="text-center bg-gray-alt"><span class="fa fa-check fa-2x text-green"></span></td>
                                    <td class="text-center"><span class="fa fa-check fa-2x text-green"></span></td>
                                    <td class="text-center bg-gray-alt"><span class="fa fa-check fa-2x text-green"></span></td>
                                    <td class="text-center"><span class="fa fa-check fa-2x text-green"></span></td>
                                    <td class="text-center bg-gray-alt"><span class="fa fa-check fa-2x text-green"></span></td>
                                </tr>
                                </tbody>
                            </table>
                            <p>
                                {{ $t('message.vsat_internet_satellite.paragraph_3', { countryName: country.name }) }}
                            </p>
                            <p v-html="$t('message.vsat_internet_satellite.paragraph_4', { link: $router.resolve({ name: $i18n.locale + '_contact' }).route.path })"></p>

                            <h2 class="section-heading text-center"><span class="li-heart mr-2"></span>{{ $t('message.vsat_internet_satellite.satisfaction_guarantee.title') }}</h2>
                            <hr class="hr-gradient">
                            <ul class="fa-ul">
                                <li><span class="fa-li fa fa-check-circle text-teal"></span>{{ $t('message.vsat_internet_satellite.satisfaction_guarantee.list.item_1') }}</li>
                                <li><span class="fa-li fa fa-check-circle text-teal"></span>{{ $t('message.vsat_internet_satellite.satisfaction_guarantee.list.item_2') }}</li>
                                <li><span class="fa-li fa fa-check-circle text-teal"></span>{{ $t('message.vsat_internet_satellite.satisfaction_guarantee.list.item_3') }}</li>
                                <li><span class="fa-li fa fa-check-circle text-teal"></span><span v-html="$t('message.vsat_internet_satellite.satisfaction_guarantee.list.item_4')"></span></li>
                            </ul>
                            <p>{{ $t('message.vsat_internet_satellite.satisfaction_guarantee.list.paragraph') }}</p></b-col>
                        <b-col md="4" xl="3">
                            <div class="text-center">
                                <div class="landing-map">
                                    <span :class="`flag fi fi-${country.code.toLowerCase()}`"></span>
                                    <img
                                            class="img-fluid"
                                            :src="'https://maps.googleapis.com/maps/api/staticmap?center=' + country.code.toLowerCase() + '-' + countryEn.name + ' country&amp;size=300x300&amp;maptype=terrain&amp;sensor=false&amp;key=AIzaSyDReG_6l9-EeeaOtJTM-GkM-okUiWEaxSc&amp;language=' + $i18n.locale"
                                            :alt="country.name + ' Map'">
                                </div>
                            </div>
                            <div class="heading-title heading-border-bottom"><h4 class="section-heading">{{ $t('message.vsat_internet_satellite.doubts') }} <small>{{ $t('message.vsat_internet_satellite.well_call_you') }}</small></h4></div>
                            <SideContact />
                        </b-col>
                    </b-row>
                </b-container>
            </section>
        </template>
    </div>
</template>

<script>
    import '@/scss/landing/landing.scss'
    import countries from '@/coverage.json'
    import SideContact from "../components/SideContact"
    import "/node_modules/flag-icons/css/flag-icons.min.css";

    export default {
        name: 'vsat-internet-satellite',
        components: { SideContact },
        metaInfo () {
            if (this.country) {
                return  {
                    htmlAttrs: {
                        lang: this.$i18n.locale
                    },
                    title: this.$t('meta.vsat_internet_satellite.title', { countryName: this.country.name }),
                    link: [
                        { rel: 'canonical', href: 'https://www.syntelix.net' + this.$route.path.replace(/\/$/, "") }
                    ],
                    meta: [
                        {
                            vmid: 'description',
                            name: 'description',
                            content: this.$t('meta.vsat_internet_satellite.description', { countryName: this.country.name })
                        },
                        {
                            vmid: 'og:title',
                            property: 'og:title',
                            content: this.$t('meta.vsat_internet_satellite.title', { countryName: this.country.name })
                        },
                        {
                            vmid: 'og:description',
                            property: 'og:description',
                            content: this.$t('meta.vsat_internet_satellite.description', { countryName: this.country.name })
                        },
                        {
                            vmid: 'og:type',
                            property: 'og:type',
                            content: 'website'
                        },
                        {
                            vmid: 'og:image',
                            property: 'og:image',
                            content: 'https://syntelix-website.s3-eu-west-1.amazonaws.com/og.jpg'
                        },
                        {
                            vmid: 'og:url',
                            property: 'og:url',
                            content: 'https://www.syntelix.net' + this.$route.path
                        }
                    ],
                    script: [{
                        type: 'application/ld+json',
                        json: {
                            "@context": "http://schema.org",
                            "@type": "Service",
                            "provider": {
                                "@type": "Corporation",
                                "legalName": "Syntelix Avances Tecnológicos S.L.",
                                "brand": "Syntelix",
                                "name": "Syntelix",
                                "description": this.$t('meta.home.description'),
                                "url": "https://www.syntelix.net",
                                "email": "info@syntelix.net",
                                "faxNumber": "(+34) 986 064 336",
                                "logo": 'https://www.syntelix.net' + require('@/assets/images/logo.png'),
                                "telephone": "(+34) 911 938 062",
                                "address": {
                                    "@type": "PostalAddress",
                                    "streetAddress": "PTL Valladares Calle C, Nave C4",
                                    "addressLocality": "Vigo",
                                    "addressCountry": "España",
                                    "postalCode": "36314"
                                }
                            },
                            "name": this.$t('meta.vsat_internet_satellite.title', { countryName: this.country.name }),
                            "description": this.$t('meta.vsat_internet_satellite.description', { countryName: this.country.name })
                        }

                    }]
                }
            }
        },

        data() {
            return {
                countries: countries
            }
        },

        mounted () {
            if (null === this.country) {
                this.$router.push({name: 'coverage'})
            }
        },

        computed: {
            country() {
                const slug = decodeURIComponent(this.$route.params.country_slug)
                const matchedCountries = this.countries[this.$i18n.locale].filter(country => {
                    return country.slug === slug
                })

                if (matchedCountries.length > 0) {
                    return matchedCountries[0]
                }

                return null
            },

            countryEn() {
                if (!this.country) {
                    return null
                }

                const matchedCountries = this.countries['en'].filter(country => {
                    return country.code === this.country.code
                })

                if (matchedCountries.length > 0) {
                    return matchedCountries[0]
                }

                return null
            }
        }
    }
</script>
