<template>
    <div v-if="emailError" class="email-error d-flex justify-content-center align-items-center">
        <div class="text-center">
            <div class="li-caution text-danger fa-5x mb-2"></div>
            <p>{{ $t('message.contact.error') }}</p>
            <b-btn variant="primary" size="sm" @click="emailError = false">{{ $t('message.contact.try_again') }}</b-btn>
        </div>
    </div>
    <div v-else-if="emailSuccess" class="email-success d-flex justify-content-center align-items-center">
        <div class="text-center">
            <div class="li-envelope text-success fa-5x mb-2"></div>
            <p>{{ $t('message.contact.success') }}</p>
            <b-btn variant="primary" size="sm" @click="emailSuccess = false">{{ $t('message.contact.send_another_request') }}</b-btn>
        </div>
    </div>
    <b-form v-else @submit="onSubmit">
        <b-form-group>
            <b-form-radio v-model="isCompany" name="some-radios" :value="true">{{ $t('message.quick_contact.im_a_business') }}</b-form-radio>
            <b-form-radio v-model="isCompany" name="some-radios" :value="false">{{ $t('message.quick_contact.im_a_private_individual') }}</b-form-radio>
        </b-form-group>

        <template v-if="isCompany">
            <label class="sr-only" for="name">{{ $t('message.quick_contact.your_name') }}</label>
            <b-input
                    v-model="form.name"
                    id="name"
                    name="name"
                    :placeholder="$t('message.quick_contact.your_name')"
            ></b-input>

            <label class="sr-only" for="phone">{{ $t('message.quick_contact.your_phone') }}</label>
            <b-input
                    :state="false === emailBlured ? null : phoneValid"
                    @blur="emailBlured = true"
                    v-model="form.phone"
                    id="phone"
                    name="phone"
                    class="mt-3"
                    :placeholder="$t('message.quick_contact.your_phone')"
            ></b-input>

            <b-form-invalid-feedback :state="false === emailBlured ? null : phoneValid">
                {{ $t('message.contact.your_phone_is_required') }}
            </b-form-invalid-feedback>

            <label class="sr-only" for="time">{{ $t('message.quick_contact.preferred_time') }}</label>
            <b-form-select v-model="form.time" id="time" name="time" :options="timeOptions" class="mt-3"></b-form-select>

            <b-button type="submit" variant="primary" block class="mt-3" :disabled="isLoading || !phoneValid">{{ $t('message.quick_contact.call_me') }}</b-button>
        </template>
        <b-alert :show="!isCompany">
            {{ $t('message.quick_contact.no_individual_services') }}
        </b-alert>
    </b-form>
</template>
<script>
    import Email from '@/assets/js/smtp'
    export default {
        name: 'SideContact',
        data() {
            return {
                isLoading: false,
                email: Email,
                emailError: false,
                emailSuccess: false,
                form: {
                    name: '',
                    phone: '',
                    time: null
                },
                emailBlured: false,
                isCompany: true
            }
        },
        computed: {
            timeOptions() {
                return [
                    { value: null, text: this.$t('message.quick_contact.preferred_time') },
                    { value: 'Anytime', text: this.$t('message.quick_contact.anytime') },
                    { value: 'Morning', text: this.$t('message.quick_contact.morning') },
                    { value: 'Afternoon', text: this.$t('message.quick_contact.afternoon') },
                ]
            },

            phoneValid() {
                return this.form.phone.length > 0;
            }
        },

        methods: {
            onSubmit(evt) {
                this.isLoading = true
                evt.preventDefault()
                this.email.send({
                    SecureToken : "a9aaf56a-ffca-4441-9969-efc8cda61f2b",
                    To : process.env.VUE_APP_CONTACT_EMAILS,
                    From : "relay@syntelix.net",
                    Subject : "[syntelix.net] New call request",
                    Body : `Name: ${this.form.name}<br/>Phone: ${this.form.phone}<br/>Preferred Time: ${this.form.time}<br/>URL: ${window.location}`
                }).then(
                    message => {
                        this.isLoading = false
                        if (message === 'OK') {
                            this.emailSuccess = true
                        } else {
                            this.emailError = true
                        }
                    }
                );
            }
        }
    }
</script>

<style lang="scss" scoped>
    .email-error,
    .email-success {
        min-height: 200px;
    }
</style>
